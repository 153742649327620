









import { FrontendChannel } from '@/@types/types';
import { SourceType } from '@linkbox/sdk';
import { defineComponent, PropType } from '@vue/composition-api';

export default defineComponent({
  name: 'AppChannel',
  props: {
    channel: Object as PropType<FrontendChannel>,
  },
  computed: {
    source() {
      return this.channel?.source;
    },
    isListed() {
      return this.channel?.listed;
    },
    name() {
      const externalName = this.source?.__typename === SourceType.PORTABLE_SOURCE && this.source?.externalName;
      return this.cloudDelay ? externalName : this.channel?.name;
    },
    description() {
      return this.channel?.description;
    },
  },
});
