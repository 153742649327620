


























import { FrontendChannel } from '@/@types/types';
import AppChannel from '@/components/channels/Channel.vue';
import AppChannelList from '@/components/channels/ChannelList.vue';
import { filterHiddenChannels } from '@/helpers/channel-helper';
import {
  ChannelType,
  filterChannelsByType,
  isAutoTranslationGeneratedChannel,
  isCloudDelayGeneratedChannel,
} from '@linkbox/sdk';
import { PropType } from 'vue';

export default {
  name: 'AppSpeakerChannelSelection',
  components: { AppChannel, AppChannelList },
  props: {
    channels: Array as Object as PropType<FrontendChannel>,
    aiInput: Object,
    aiOutputLanguages: Array,
    isAdmin: Boolean,
  },
  computed: {
    selectableChannels() {
      return filterChannelsByType(filterHiddenChannels(this.channels, this.isAdmin), [ChannelType.LISTEN_ONLY_CHANNEL]);
    },
    filteredChannels() {
      return this.selectableChannels.filter(
        (channel) => !isCloudDelayGeneratedChannel(channel) && !isAutoTranslationGeneratedChannel(channel)
      );
    },
    hasAiOutputLanguages() {
      return this.aiOutputLanguages && this.aiOutputLanguages.length;
    },
    aiInputChannel() {
      return this.aiInput
        ? {
            source: this.aiInput,
            name: 'Automatic Translation',
            description: `Your speech gets translated into ${
              this.aiOutputLanguages ? this.aiOutputLanguages.join(', ') : ''
            }`,
            listed: true,
          }
        : undefined;
    },
    adminAppUrl() {
      return `${process.env.VUE_APP_ADMIN_URL || '/admin'}/audiocast`;
    },
  },
};
