<template>
  <div class="text-center">
    <div class="subtitle-1">
      <b>{{ selectedChannelName }}</b>
    </div>
    <div v-if="selectedChannelDescription">{{ selectedChannelDescription }}</div>

    <app-audio-controls
      :stream="stream"
      :show-side-buttons="connected"
      :muted="muted"
      start-icon="mdi-microphone"
      class="mt-6"
      @start="connected ? stopRecording() : startRecording()"
      @cancel="stopRecording"
      @toggleMute="toggleMute"
    />

    <app-in-background-information :active="connected" />

    <div class="mt-12" :class="{ invisible: connected }">
      Press <v-icon>mdi-microphone</v-icon> button and start speaking
    </div>
  </div>
</template>

<script>
import AppInBackgroundInformation from '@/components/audio/InBackgroundInformation';
import { getMediaRoomUrl } from '@/helpers/channel-helper';
import * as _ from 'lodash';
import AppAudioControls from '../shared/AudioControls';

export default {
  name: 'AppSpeakerRecord',
  components: { AppInBackgroundInformation, AppAudioControls },
  props: {
    channel: Object,
    connected: Boolean,
    stream: MediaStream,
    muted: Boolean,
  },
  data() {
    return {
      inAnimation: false,
    };
  },
  computed: {
    selectedChannelName() {
      return _.get(this.channel, 'name');
    },
    selectedChannelDescription() {
      return _.get(this.channel, 'description');
    },
    mediaRoomUrl() {
      return getMediaRoomUrl(this.channel);
    },
  },
  methods: {
    async startRecording() {
      this.$mediaSoup.connectAndProduce(this.mediaRoomUrl, this.selectedDeviceId);
    },
    stopRecording() {
      this.$mediaSoup.disconnect();
      this.cancel();
    },
    cancel() {
      this.$emit('cancel');
    },
    toggleMute() {
      if (this.muted) {
        this.$mediaSoup.unmuteMic();
      } else {
        this.$mediaSoup.muteMic();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.invisible {
  visibility: hidden;
}
</style>
