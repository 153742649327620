
















import AppBrowserList from '@/components/shared/BrowserList.vue';
import AppDeviceNotSupportedForRtc from '@/components/shared/DeviceNotSupportedForRtc.vue';
import { browsersDataFor, isSupportedBrowserForPlaying, supportedBrowserForPlaying } from '@/helpers/browser-detection';
import { isDeviceSupported } from '@linkbox/rtc-sdk/dist/cjs/device-support';
import { Configuration } from '@linkbox/sdk';
import { defineComponent, PropType } from '@vue/composition-api';
import AppChannels from '../components/channels/index.vue';

export default defineComponent({
  name: 'ChannelsView',
  components: { AppBrowserList, AppChannels, AppDeviceNotSupportedForRtc },
  props: {
    channels: Array,
    configuration: Object as PropType<Configuration>,
    loading: Boolean,
  },
  computed: {
    isSupportedBrowser(): boolean {
      return isSupportedBrowserForPlaying;
    },
    supportedBrowser(): any[] {
      return browsersDataFor(supportedBrowserForPlaying);
    },
    isDeviceSupported(): boolean {
      return isDeviceSupported();
    },
  },
});
