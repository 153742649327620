<template>
  <div class="text-center">
    <h1 class="mb-n1">Speak</h1>
    <app-speaker-browser-not-supported-message v-if="!isSupportedBrowserForRecording()" class="mt-4" />
    <div v-else>
      <app-transition-expand>
        <app-speaker-channel-selection
          v-if="!selectedChannel"
          :channels="channels"
          :ai-input="aiInput"
          :ai-output-languages="autoTranslationOutputLanguages"
          :is-admin="isAdmin"
          @input="selectedChannel = $event"
        />
      </app-transition-expand>
      <app-transition-expand>
        <app-speaker-record
          v-if="selectedChannel"
          ref="speakerRecord"
          :channel="selectedChannel"
          :connected="connected"
          :stream="producerAudioStream"
          :muted="producerMuted"
          @cancel="selectedChannel = null"
        />
      </app-transition-expand>
    </div>
  </div>
</template>

<script>
import { firstInputByType, InputType } from '@linkbox/sdk';
import { createNamespacedHelpers } from 'vuex';
import { isSupportedBrowserForRecording } from '../../helpers/browser-detection';
import { updateQuery } from '../../helpers/graphql_helper';
import AUTO_TRANSLATION_OUTPUT_LANGUAGES from '../shared/gql/AutoTranslationOutputLanguages.query.gql';
import INPUTS from '../shared/gql/Inputs.query.gql';
import INPUTS_SUBSCRIPTION from '../shared/gql/Inputs.subscription.gql';
import AppTransitionExpand from '../shared/TransitionExpand';
import AppSpeakerBrowserNotSupportedMessage from './SpeakerBrowserNotSupportedMessage';
import AppSpeakerChannelSelection from './SpeakerChannelSelection';
import AppSpeakerRecord from './SpeakerRecord';
const authStore = createNamespacedHelpers('auth');
const mediaSoupConnectionStore = createNamespacedHelpers('mediaSoupConnection');
const systemInformationStore = createNamespacedHelpers('systemInformation');

export default {
  name: 'AppSpeaker',
  components: {
    AppSpeakerBrowserNotSupportedMessage,
    AppSpeakerRecord,
    AppSpeakerChannelSelection,
    AppTransitionExpand,
  },
  props: {
    channels: Array,
  },
  data() {
    return {
      selectedChannel: null,
      selectedDeviceId: null,
    };
  },
  apollo: {
    inputs: {
      query: INPUTS,
      subscribeToMore: {
        document: INPUTS_SUBSCRIPTION,
        variables() {
          return {
            tenantId: this.tenantId,
          };
        },
        updateQuery: updateQuery('input', 'inputs'),
      },
    },
    autoTranslationOutputLanguages: {
      query: AUTO_TRANSLATION_OUTPUT_LANGUAGES,
    },
  },
  async mounted() {
    this.$mediaSoup.disconnect();
  },
  beforeDestroy() {
    if (this.$refs.speakerRecord) {
      this.$refs.speakerRecord.stopRecording();
    }
    this.$mediaSoup.disconnect();
  },
  methods: {
    isSupportedBrowserForRecording() {
      return isSupportedBrowserForRecording;
    },
  },
  computed: {
    ...mediaSoupConnectionStore.mapState({
      connected: (state) => state.connected,
      producerAudioStream: (state) => state.audioProducer.stream,
      producerMuted: (state) => state.audioProducer.muted,
    }),
    ...systemInformationStore.mapState({ tenantId: 'tenantId' }),
    ...authStore.mapGetters({
      isAdmin: 'isAdmin',
    }),
    aiInput() {
      return firstInputByType(this.inputs, InputType.AI_SOURCE_INPUT);
    },
  },
};
</script>

<style scoped lang="scss"></style>
