<template>
  <div class="mt-n12">
    <v-btn
      fab
      :outlined="!muted"
      color="primary"
      class="right-button mb-2"
      :class="{ show: showSideButtons }"
      @click="$emit('toggleMute')"
      aria-label="Toggle mute"
    >
      <v-icon v-if="showSideButtons" size="32">mdi-microphone-off</v-icon>
    </v-btn>
    <app-audio-button
      :active="showSideButtons"
      :stream="stream"
      @click="$emit('start')"
      class="mb-2"
      aria-label="Start record"
    >
      <v-icon class="text--white" size="56">{{ startIcon }}</v-icon>
    </app-audio-button>
    <v-btn
      fab
      color="error"
      outlined
      class="left-button"
      :class="{ show: showSideButtons }"
      @click="$emit('cancel')"
      aria-label="Cancel"
    >
      <v-icon size="32">mdi-close</v-icon>
    </v-btn>
  </div>
</template>

<script>
import AppAudioButton from './AudioButton';
export default {
  name: 'AppAudioControls',
  components: { AppAudioButton },
  props: {
    showSideButtons: Boolean,
    muted: Boolean,
    stream: MediaStream,
    startIcon: String,
  },
};
</script>

<style scoped lang="scss">
$animation_time: 0.5;

.right-button {
  z-index: 1;
  opacity: 0;
  transition: transform ease-in-out $animation_time + s, opacity ease-in-out $animation_time / 2 + s;
  transform-origin: 50% 149px;
  &.show {
    transform: rotate(90deg);
    transition: transform ease-in-out $animation_time + s,
      opacity ease-in-out $animation_time / 2 + s $animation_time / 2 + s;
    opacity: 1;
  }
  .v-icon {
    transition: transform ease-in-out $animation_time + s;
    transform: rotate(-90deg);
  }
}
.left-button {
  transition: transform ease-in-out $animation_time + s;
  transform-origin: 50% -93px;
  &.show {
    transform: rotate(90deg);
    .v-icon {
      transition: transform ease-in-out$animation_time + s;
      transform: rotate(-90deg);
    }
  }
}
</style>
