import { render, staticRenderFns } from "./SpeakerRecord.vue?vue&type=template&id=195fb596&scoped=true&"
import script from "./SpeakerRecord.vue?vue&type=script&lang=js&"
export * from "./SpeakerRecord.vue?vue&type=script&lang=js&"
import style0 from "./SpeakerRecord.vue?vue&type=style&index=0&id=195fb596&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "195fb596",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
