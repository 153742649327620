import { render, staticRenderFns } from "./SpeakerChannelSelection.vue?vue&type=template&id=ae51f4be&scoped=true&"
import script from "./SpeakerChannelSelection.vue?vue&type=script&lang=ts&"
export * from "./SpeakerChannelSelection.vue?vue&type=script&lang=ts&"
import style0 from "./SpeakerChannelSelection.vue?vue&type=style&index=0&id=ae51f4be&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ae51f4be",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
installComponents(component, {VDivider})
