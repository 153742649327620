































import { FrontendChannel } from '@/@types/types';
import AppChannelList from '@/components/channels/ChannelList.vue';
import { filterHiddenChannels, getMediaRoomUrl } from '@/helpers/channel-helper';
import { Channel, ChannelType, Configuration, filterChannelsByType } from '@linkbox/sdk';
import { defineComponent, PropType } from '@vue/composition-api';
import * as _ from 'lodash';
import { createNamespacedHelpers, mapActions, mapState } from 'vuex';
import AppTransitionExpand from '../shared/TransitionExpand.vue';
import AppSelectedChannel from './SelectedChannel.vue';

const audioConsumer = createNamespacedHelpers('mediaSoupConnection/audioConsumer');
const auth = createNamespacedHelpers('auth');
const notifications = createNamespacedHelpers('notifications');
const systemInformation = createNamespacedHelpers('systemInformation');

export default defineComponent({
  name: 'AppChannels',
  components: { AppChannelList, AppSelectedChannel, AppTransitionExpand },
  props: {
    channels: Array,
    configuration: Object as PropType<Configuration>,
    loading: Boolean,
  },
  methods: {
    ...mapActions({ selectChannel: 'selectChannel' }),
    ...audioConsumer.mapActions({ togglePlay: 'togglePlay', pause: 'pause' }),
    ...notifications.mapActions({ showError: 'showError' }),
    onChannelUnselect() {
      this.$mediaSoup.disconnect();
      this.pause();
      this.selectChannel(null);
    },
    async onChannelSelect(channel: FrontendChannel) {
      if (!(await this.$mediaSoup.canConnect())) {
        this.showError({ title: 'Too many listeners', description: 'Try again or ask your event operator for help.' });
        return;
      }
      await this.$mediaSoup.disconnect();
      this.selectChannel(channel);
      const mediaRoomUrl = getMediaRoomUrl(channel);
      if (mediaRoomUrl) {
        await this.$mediaSoup.connectAndConsume(mediaRoomUrl, channel.id);
      }
    },
  },
  watch: {
    async channels(channels) {
      if (this.selectedChannel) {
        const updatedChannel = _.find(filterHiddenChannels(channels, this.isLoggedIn), { id: this.selectedChannel.id });

        if (!updatedChannel || !_.get(updatedChannel, 'enabled', false)) {
          this.onChannelUnselect();
          return;
        }

        const updatedChannelWithDelayInfo = {
          ...updatedChannel,
          cloudDelay: this.selectedChannel.cloudDelay,
        };

        const selectedChannelRoomUrl = getMediaRoomUrl(this.selectedChannel);
        const updatedChannelRoomUrl = getMediaRoomUrl(updatedChannelWithDelayInfo);
        if (selectedChannelRoomUrl !== updatedChannelRoomUrl) {
          this.onChannelSelect(updatedChannelWithDelayInfo);
        }
      }
    },
  },
  computed: {
    ...mapState({
      selectedChannel: 'selectedChannel',
    }),
    ...audioConsumer.mapState({
      mergedStream: 'mergedStream',
      audioConsumer: 'audioConsumer',
    }),
    ...systemInformation.mapState({ tenantId: 'tenantId' }),
    ...systemInformation.mapGetters({ isCloudDeployment: 'isCloudDeployment' }),
    ...auth.mapGetters({
      isLoggedIn: 'isLoggedIn',
    }),
    showSelectedChannel(): Channel {
      return this.selectedChannel;
    },
    visibleAudiocastChannels(): Channel[] {
      return filterChannelsByType(filterHiddenChannels(this.channels, this.isLoggedIn), [
        ChannelType.LISTEN_ONLY_CHANNEL,
      ]);
    },
    title(): string | undefined | null {
      return this.configuration?.title;
    },
  },
});
